import CryptoJS from "crypto-js"
import axios from "axios"

export const getContractHash = fileBuffer => {
  try {
    const buffer = fileBuffer
    const data = CryptoJS.lib.WordArray.create(buffer)
    const fileHash = CryptoJS.SHA256(data).toString(CryptoJS.enc.Hex)
    return process.env.GATSBY_HASH_HEADER + fileHash
  } catch (error) {
    return null
  }
}

export const validateContractHash = async fileHash => {
  try {
    const contractFile = await axios
      .get(
        `${process.env.GATSBY_FIRMA_LCD_URL}/firmachain/firmachain/contract/contractFile/${encodeURIComponent(
          fileHash
        )}`
      )
      .then(res => {
        return res.data.ContractFile
      })

    const txHash = await axios
      .get(`${process.env.GATSBY_CHAIN_SERVER_URL}/contract/getTxHash`, {
        params: {
          fileHash,
          contractHash: JSON.parse(contractFile.metaDataJsonString)
            .contractHash,
        },
      })
      .then(res => {
        return res.data.payload[0]
      })
      .catch(e => {
        return null
      })

    return {
      ...contractFile,
      txHash,
    }
  } catch (error) {
    console.log(error)
    return null;
  }
}
