import * as React from "react";

import Seo from "components/seo";
import LayoutBasic from "../../templates/layoutBasic";
import { CONTRACT_VERIFICATION_META, CONTRACT_VERIFICATION_META_EN } from "../../constants/data/meta_data";
import ContractVerification from "organisms/contractVerification";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const ContractVerificationPage = () => {
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return CONTRACT_VERIFICATION_META_EN;

        return CONTRACT_VERIFICATION_META;
    }, [i18n.language]);

    return (
        <LayoutBasic>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <ContractVerification />
        </LayoutBasic>
    );
};

export default ContractVerificationPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
