import * as React from "react"
import {
  LinearProgress,
  Typography,
  Box,
  styled,
  linearProgressClasses,
} from "@mui/material"

const StyledLinearProgress = styled(LinearProgress)(
  ({
    backgroundColor = "#ffffff",
    color = "#cd001a",
    borderRadius = "4px",
    height = "4px",
  }) => ({
    height: height,
    borderRadius: borderRadius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: backgroundColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: borderRadius,
      backgroundColor: color,
    },
  })
)

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%"}}>
        <StyledLinearProgress variant="determinate" {...props} />
      </Box>
      {!props.hideLabel && (
          <Typography sx={{fontSize:"13px",color:"#1d1d1d",marginLeft:"12px",...props.fontStyle}}>{`${Math.round(
            props.value
          )}%`}</Typography>
      )}
    </Box>
  )
}
const LinearProgressBar = props => {
  return (
    <Box sx={{ width: "33%" }}>
      <LinearProgressWithLabel value={props.percent} hideLabel={props.hideLabel} />
    </Box>
  )
}

export default LinearProgressBar
