import * as React from "react"
import {
  CircularProgress,
  Typography,
  Box,
  styled,
  circularProgressClasses,
} from "@mui/material"
import IC_CHECK_FIELD from "svg/IC_CHECK_FIELD.svg"

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        thickness={3}
        size={"140px"}
        {...props}
        value={100}
        sx={{ color: props.backgroundColor || "#dddddd", position: "absolute" }}
      />
      <CircularProgress
        variant="determinate"
        thickness={3}
        size={"140px"}
        {...props}
        sx={{ color: props.color || "#c62731" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "32px",
            fontWeight: "bold",
            fontFamily: "Lato",
            color: "#1d1d1d",
            ...props.fontStyle,
          }}
        >
          {props.showCheck && props.value === 100 ? (
            <IC_CHECK_FIELD />
          ) : (
            `${Math.round(props.value)}%`
          )}
          {}
        </Typography>
      </Box>
    </Box>
  )
}

const CirculaProgressBar = props => {
  return (
    <CircularProgressWithLabel
      value={props.percent}
      hideLabel={props.hideLabel}
      showCheck={props.showCheck}
    />
  )
}

export default CirculaProgressBar
