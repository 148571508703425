import { IMG_CONTRACT_VERIFICATION_LOGO } from "../media/images";

export const CONTRACT_VERIFICATION_DATA = {
    logo: IMG_CONTRACT_VERIFICATION_LOGO,
    url: "https://verify.firmachain.io"
};

// export const CONTRACT_CERTIFICATION_TITLE = "계약서 검증하기";
// export const CONTRACT_CERTIFICATION_SUB_TITLE = "도뉴에서 체결한 계약서인지 확인해보세요.";

// export const S_UPLOAD_CONTRACT_FILE_TEXT = "이곳에 도뉴에서 체결한 계약서 파일을 <br /> 직접 끌어오거나, <br /> 클릭해서 선택하세요.";
// export const UPLOAD_CONTRACT_FILE_TEXT = "이곳에 도뉴에서 체결한 계약서 파일을 <br /> 직접 끌어오거나, 클릭해서 선택하세요.";

// export const UPLOAD_CONTRACT_FILE_RULE1 = "1. 파일은 1개만 선택 가능합니다.";
// export const UPLOAD_CONTRACT_FILE_RULE2 = "2. PDF 파일만 선택 가능합니다.";

// export const AGAIN_UPLOAD_BTN_TEXT = "다시 업로드";

// export const SELECT_FILE_BTN_TEXT = "파일 선택";

// export const REMOVE_FILE_BTN_TEXT = "삭제";

// export const SUCCESS_CONTRACT_FILE = "계약서 검증 완료";

// export const VERIFYING_CONTRACT_FILE = "계약서를 검증하고 있습니다. <br /> 잠시만 기다려주세요.";

// export const SIGNED_BY_DONUE_CONTRACT_FILE = "도뉴에서 체결한 계약서입니다.";
// export const NOT_SIGNED_BY_DONUE_CONTRACT_FILE = "도뉴에서 체결한 계약서가 아닙니다.";

// export const DETAIL_BTN_TEXT = "자세히 보러가기";

// export const TOOL_TIP_TEXT1 = "자세히 보러가기에서 제공되는 정보는 무엇인가요?";
// export const TOOL_TIP_TEXT2 = "도뉴에서 체결한 계약서인데 검증이 안되나요?";

// export const VERIFY_BTN_TEXT = "검증하기";
// export const VERIFY_BTN_TEXT_BY_SUCCESS = "다른 계약서 검증하기";

// export const ERROR_FILE_EXTENSION = "지원하지 않는 파일 형식입니다.";
// export const ERROR_FILE_LENGTH = "한 개의 파일만 업로드 가능합니다.";
