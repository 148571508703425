import React, { useCallback, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import useFakePercentage from "../../hooks/useFakePercentage";
import { getContractHash, validateContractHash } from "../../utils/firma";
import CirculaProgressBar from "./CirculaProgressBar";
import LinearProgressBar from "./LinearProgressBar";
import IC_DOC_COMPLETE from "svg/IC_DOC_COMPLETE.svg";
import IC_DOC from "svg/IC_DOC.svg";
import IC_DOC_ERROR from "svg/IC_DOC_ERROR.svg";
import IC_DOC_CHECK from "svg/IC_DOC_CHECK.svg";
import IC_TOOL_TIP from "svg/IC_TOOL_TIP.svg";
import IC_ERROR from "svg/IC_ERROR.svg";
import { Box, Button, Stack } from "@mui/material";
import { convertFileSize } from "../../utils/common";
import useBreakpoint from "../../hooks/useBreakpoint";
import Image from "components/image/image";

import {
    AGAIN_UPLOAD_BTN_TEXT,
    CONTRACT_VERIFICATION_DATA,
    DETAIL_BTN_TEXT,
    ERROR_FILE_EXTENSION,
    NOT_SIGNED_BY_DONUE_CONTRACT_FILE,
    REMOVE_FILE_BTN_TEXT,
    SELECT_FILE_BTN_TEXT,
    SIGNED_BY_DONUE_CONTRACT_FILE,
    SUCCESS_CONTRACT_FILE,
    S_UPLOAD_CONTRACT_FILE_TEXT,
    TOOL_TIP_TEXT1,
    TOOL_TIP_TEXT2,
    UPLOAD_CONTRACT_FILE_RULE1,
    UPLOAD_CONTRACT_FILE_RULE2,
    UPLOAD_CONTRACT_FILE_TEXT,
    VERIFYING_CONTRACT_FILE,
    VERIFY_BTN_TEXT,
    VERIFY_BTN_TEXT_BY_SUCCESS
} from "../../constants/data/contract-verification";
import { useTranslation } from "react-i18next";

const UploadWrapper = styled.div`
    max-width: 750px;
    width: 100%;
    height: 404px;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 61px 0 10px 0;

    ${(p) =>
        p.breakpoint === "S" &&
        `
  margin: 36px 0 24px 0;
  padding: 16px;
  height: 324px;
  `};
`;

const Btn = styled(Box)`
    all: unset;
    box-sizing: border-box;
    padding: 5px 8px 6px;
    border-radius: 3px;
    border: solid 1px #713838;
    font-size: 12px;
    text-align: center;
    color: #713838;
    cursor: pointer;
    line-height: 1.17;
    letter-spacing: -0.36px;
`;

const FileUpload = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 16px;
    border: dashed 1px ${(p) => (p.borderColor ? p.borderColor : "#ddd")};
    background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : "#f8f8f8")};
    cursor: ${(p) => (p.cursor ? p.cursor : "")};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${(p) =>
        p.breakpoint === "S" &&
        `
    padding: 12px;
  `};
`;

const BaseButton = styled.button`
    all: unset;
    box-sizing: border-box;

    background-color: #713838;
    color: #ffffff;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-weight: 500;
    justify-content: center;
    line-height: 1.18;
    letter-spacing: -0.66px;
    font-stretch: normal;
    font-style: normal;

    &:active:enabled {
        background-color: #451818;
    }

    ${(p) =>
        p.disabled &&
        `
  color: #b5b5b5;
  background-color: #ddd;
  `}
`;

const UploadButton = styled(BaseButton)`
    font-size: 16px;
    width: 180px;
    padding: 12px;
`;

const PoweredBy = styled.div`
    width: 100%;
    max-width: 750px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
    padding-right: 5px;
    gap: 5px;
`;

const PoweredByLink = styled.div``;

const VerifyButton = styled(BaseButton)`
    width: 340px;
    font-size: 22px;
    padding: 18px;

    ${(p) =>
        p.breakpoint === "S" &&
        `
    width:100%;
    font-size: 16px;
    padding:11px;
  `};
`;

function Verifier() {
    const { t, i18n } = useTranslation();

    const [fileInfo, setFileInfo] = useState(null);
    const [contractFileInfo, setContractFileInfo] = useState(null);
    const [Error, setError] = useState([]);
    const breakpoint = useBreakpoint();

    const { isRunning: isRunningFileProgress, reset: resetFileProgress, restart: restartFileProgress, percentage: fileProgressPercent } = useFakePercentage({ ms: 30, delayFinishMs: 500 });

    const { isRunning: isRunningVerifyProgress, reset: resetVerifyProgress, restart: restartVerifyProgress, percentage: verifyProgressPercent } = useFakePercentage({ ms: 100, delayFinishMs: 1000 });

    const goVerify = useCallback(() => {
        if (!contractFileInfo) return;
        window.open(process.env.GATSBY_CONTRACT_VERIFY_URL + encodeURIComponent(contractFileInfo.fileHash));
    }, [contractFileInfo]);

    const goExplorer = useCallback(() => {
        if (!contractFileInfo) return;
        if (!contractFileInfo.txHash) {
            console.error("검증은 되었지만 해시가 없음");
        }

        window.open(process.env.GATSBY_EXPLORER_URL + "/transactions/" + contractFileInfo.txHash);
    }, [contractFileInfo]);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setError((cur) => [...cur, "ERROR_FILE_EXTENSION"]);
            return;
        }
        if (acceptedFiles.length !== 1) {
            setError((cur) => [...cur, "ERROR_FILE_LENGTH"]);
            return;
        }

        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
            restartFileProgress();
            const binaryStr = reader.result;
            setFileInfo({
                name: file.name,
                size: file.size,
                hash: getContractHash(binaryStr)
            });
        };
        reader.readAsArrayBuffer(file);
    }, []);

    const cancel = useCallback(() => {
        setError([]);
        resetFileProgress();
        resetVerifyProgress();
        setFileInfo(null);
        setContractFileInfo(null);
    }, []);

    const status = useMemo(() => {
        if (Error.length) return "ERROR_EXIST";
        if (verifyProgressPercent === 100 && !isRunningVerifyProgress) return "FILE_VERIFIED";
        if (isRunningVerifyProgress) return "VERIFYING_FILE";
        if (fileInfo && fileProgressPercent && !isRunningFileProgress) return "VERIFY_FILE";
        if (isRunningFileProgress) return "UPLOADING_FILE";
        if (!fileInfo && !isRunningFileProgress) return "UPLOAD_FILE";
        if (isRunningFileProgress) return;
        if (!contractFileInfo) return 1;
    }, [isRunningFileProgress, isRunningVerifyProgress, fileInfo, contractFileInfo, Error, verifyProgressPercent, fileProgressPercent]);

    const verifyButtonText = useMemo(() => {
        if (status === "FILE_VERIFIED") return t("VERIFY_BTN_TEXT_BY_SUCCESS");
        return t("VERIFY_BTN_TEXT");
    }, [status]);

    const handleClickVerify = useCallback(() => {
        if (!fileInfo) return;
        if (status === "FILE_VERIFIED") {
            cancel();
            return;
        }
        validateContractHash(fileInfo.hash).then((data) => {
            setContractFileInfo(data);
            restartVerifyProgress();
        });
    }, [fileInfo, status]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            pdf: [".pdf"]
        }
    });

    return (
        <Stack alignItems={"center"} sx={{ width: "100%" }}>
            <UploadWrapper breakpoint={breakpoint}>
                <FileUpload
                    {...(status === "UPLOAD_FILE" ? getRootProps() : {})}
                    breakpoint={breakpoint}
                    cursor={status === "UPLOAD_FILE" ? "pointer" : ""}
                    borderColor={((status === "FILE_VERIFIED" && !contractFileInfo) || Error.length > 0) && "#cd001a"}
                    backgroundColor={status === "FILE_VERIFIED" ? (contractFileInfo ? "#faf7ef" : "#ffffff") : ""}
                >
                    {status === "ERROR_EXIST" && (
                        <>
                            <IC_ERROR />
                            <Box
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    // textAlign: center,
                                    color: "#cd001a",
                                    mt: "24px",
                                    mb: "21px"
                                }}
                            >
                                {Error[0] === "ERROR_FILE_EXTENSION" && t("ERROR_FILE_EXTENSION")}
                                {Error[0] === "ERROR_FILE_LENGTH" && t("ERROR_FILE_LENGTH")}
                            </Box>
                            <Box
                                component={"button"}
                                sx={{
                                    all: "unset",
                                    boxSizing: "border-box",

                                    padding: "5px 14px 6px",
                                    borderRadius: "3px",
                                    backgroundColor: "#efefef",
                                    cursor: "pointer",
                                    fontSize: "12px"
                                }}
                                onClick={cancel}
                            >
                                {t("AGAIN_UPLOAD_BTN_TEXT")}
                            </Box>
                        </>
                    )}
                    {status === "UPLOAD_FILE" && (
                        <>
                            <input {...getInputProps()} />
                            <Stack
                                direction="column"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: 1.38,
                                    letterSpacing: "-0.48px",
                                    textAlign: "center",
                                    color: "#713838",
                                    maxWidth: "320px",
                                    width: "100%",
                                    wordBreak: "break-all",
                                    marginBottom: "12px",
                                    "& > div": {
                                        whiteSpace: "pre-line"
                                    }
                                }}
                            >
                                {breakpoint === "S" && <div dangerouslySetInnerHTML={{ __html: t("S_UPLOAD_CONTRACT_FILE_TEXT") }} />}
                                {breakpoint !== "S" && <div dangerouslySetInnerHTML={{ __html: t("UPLOAD_CONTRACT_FILE_TEXT") }} />}
                            </Stack>
                            <Stack
                                direction="column"
                                className="file-upload-content"
                                sx={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    lineHeight: 1.54,
                                    letterSpacing: "-0.39px",
                                    color: "#1e1e1e",
                                    maxWidth: "258px",
                                    width: "100%"
                                }}
                            >
                                <div>{t("UPLOAD_CONTRACT_FILE_RULE1")}</div>
                                <div>{t("UPLOAD_CONTRACT_FILE_RULE2")}</div>
                                <div>{t("UPLOAD_CONTRACT_FILE_RULE3")}</div>
                            </Stack>
                            <UploadButton style={{ marginTop: "24px" }}>{t("SELECT_FILE_BTN_TEXT")}</UploadButton>
                        </>
                    )}
                    {(status === "UPLOADING_FILE" || status === "VERIFY_FILE") && (
                        <>
                            {status === "UPLOADING_FILE" ? <IC_DOC width={"80px"} height={"80px"} /> : <IC_DOC_COMPLETE />}
                            <Box sx={{ fontSize: "18px", pt: "12px", pb: "15px" }}>{fileInfo?.name}</Box>
                            <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "20px", width: "100%" }}>
                                {status === "UPLOADING_FILE" ? (
                                    <LinearProgressBar percent={fileProgressPercent} />
                                ) : (
                                    <Stack direction={"row"} alignItems="center" justifyContent={"center"}>
                                        <Box
                                            sx={{
                                                fontFamily: "Lato",
                                                fontSize: "14px",
                                                color: "#707070"
                                            }}
                                        >
                                            {convertFileSize(fileInfo.size)}
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "4px",
                                                height: "4px",
                                                margin: "12px",
                                                backgroundColor: "#999",
                                                borderRadius: "100%"
                                            }}
                                        ></Box>
                                        <Box
                                            component={"button"}
                                            sx={{
                                                all: "unset",
                                                boxSizing: "border-box",

                                                cursor: "pointer",
                                                color: "#1e1e1e",
                                                fontSize: "14px"
                                            }}
                                            onClick={cancel}
                                        >
                                            {t("REMOVE_FILE_BTN_TEXT")}
                                        </Box>
                                    </Stack>
                                )}
                            </Stack>
                        </>
                    )}
                    {status === "VERIFYING_FILE" && (
                        <>
                            <CirculaProgressBar percent={verifyProgressPercent} showCheck />
                            <Box sx={{ textAlign: "center", mt: "28px", height: "50px" }}>{verifyProgressPercent === 100 ? <>{t("SUCCESS_CONTRACT_FILE")}</> : <div dangerouslySetInnerHTML={{ __html: t("VERIFYING_CONTRACT_FILE") }} />}</Box>
                        </>
                    )}
                    {status === "FILE_VERIFIED" && (
                        <Stack alignItems={"center"} justifyContent={"center"} flex="1">
                            <Box
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    mb: "20px",
                                    color: contractFileInfo ? "#713838" : "#cd001a"
                                }}
                            >
                                {contractFileInfo ? t("SIGNED_BY_DONUE_CONTRACT_FILE") : t("NOT_SIGNED_BY_DONUE_CONTRACT_FILE")}
                            </Box>
                            {contractFileInfo ? <IC_DOC_CHECK width={"80px"} height={"80px"} /> : <IC_DOC_ERROR width={"80px"} height={"80px"} />}
                            <Box
                                sx={{
                                    mt: "8px",
                                    mb: "20px",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    letterSpacing: "-0.42px",
                                    textAlign: "center",
                                    color: "#1e1e1e"
                                }}
                            >
                                {fileInfo.name}
                            </Box>

                            <Btn
                                component={"button"}
                                sx={{
                                    visibility: contractFileInfo ? "visivible" : "hidden"
                                }}
                                onClick={goVerify}
                            >
                                {t("DETAIL_BTN_TEXT")}
                            </Btn>
                        </Stack>
                    )}
                    {status === "FILE_VERIFIED" && (
                        <Stack
                            direction={"row"}
                            gap="4px"
                            sx={{
                                width: "100%",
                                color: "#999999",
                                fontSize: "14px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                contractFileInfo
                                    ? window.open(`https://guide.donue.co.kr/${i18n.language === "en" ? "en" : "1"}/undefined-5-1/undefined#undefined-1`)
                                    : window.open(`https://guide.donue.co.kr/${i18n.language === "en" ? "en" : "1"}/undefined-5-1/undefined#undefined-1`);
                            }}
                        >
                            <IC_TOOL_TIP /> {contractFileInfo ? t("TOOL_TIP_TEXT1") : t("TOOL_TIP_TEXT2")}
                        </Stack>
                    )}
                </FileUpload>
            </UploadWrapper>

            <PoweredBy>
                <span style={{ fontSize: "10px" }}>Powered by</span>
                <PoweredByLink
                    onClick={() => {
                        window.open(CONTRACT_VERIFICATION_DATA.url, "_blank");
                    }}
                >
                    <Image filename={CONTRACT_VERIFICATION_DATA.logo} alt={"firma-verify"} style={{ width: "93px", height: "16px", cursor: "pointer" }} />
                </PoweredByLink>
            </PoweredBy>

            <VerifyButton disabled={!(status === "VERIFY_FILE" || status === "FILE_VERIFIED")} style={{ alignSelf: "center" }} onClick={handleClickVerify} breakpoint={breakpoint}>
                {verifyButtonText}
            </VerifyButton>
        </Stack>
    );
}

export default Verifier;
