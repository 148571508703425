import React, { useCallback, useState } from "react"
import { useInterval } from "react-use"

function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const useFakePercentage = ({ms = 100,delayFinishMs=1000}) => {
  const [percentage, setPercentage] = useState(0)
  const [isRunning, setIsRunning] = useState(false)

  const reset = useCallback(() => {
    setPercentage(0)
    setIsRunning(false)
  }, [])

  const restart = useCallback(() => {
    setPercentage(0)
    setIsRunning(true)
  }, [])

  useInterval(
    () => {
      if (percentage === 100) {
        setTimeout(() => {
          setIsRunning(false)
        }, delayFinishMs)
      }

      setPercentage(cur => {
        const num = getRandom(1, 10)
        if (cur + num >= 100) return 100
        return cur + num
      })
    },
    isRunning ? ms : null
  )

  return {
    reset,
    isRunning,
    restart,
    percentage,
  }
}

export default useFakePercentage
