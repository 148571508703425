import React, { useMemo } from "react";
import styled from "styled-components";
import Verifier from "./Verifier";
import { Container } from "../../components/style/layoutStyle";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useTranslation } from "react-i18next";
import ResponsiveMedia from "utils/responsive_media";

const Text = styled.div`
    width: ${({ theme }) => (theme.language === "ko" ? "365px" : "455px")};
    text-align: center;
    color: #1e1e1e;

    white-space: pre-line;
`;

const ContractCertification = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const { isMobile } = ResponsiveMedia();

    const containerStyle = useMemo(() => {
        const style = {
            fontFamily: "NotoSansKR",
            flexDirection: "column",
            margin: "80px 0 120px 0",
            padding: "0px 20px",
            width: "100%"
        };

        if (breakpoint === "S") {
            style.margin = "64px 0 80px 0";
        }

        return style;
    }, [breakpoint]);
    const titleStyle = useMemo(() => {
        const style = { fontWeight: "bold", marginBottom: "12px", fontSize: "32px" };
        if (breakpoint === "S") {
            style.fontSize = "24px";
            style.marginBottom = "8px";
        }

        return style;
    }, [breakpoint]);
    const subtitleStyle = useMemo(() => {
        const style = {};
        if (breakpoint === "S") {
            style.fontSize = "14px";
        }

        return style;
    }, [breakpoint]);

    return (
        <Container style={containerStyle}>
            <Text style={titleStyle}>{isMobile ? t("CONTRACT_CERTIFICATION_TITLE_MOBILE") : t("CONTRACT_CERTIFICATION_TITLE")}</Text>
            <Text style={subtitleStyle}>{isMobile ? t("CONTRACT_CERTIFICATION_SUB_TITLE_MOBILE") : t("CONTRACT_CERTIFICATION_SUB_TITLE")}</Text>
            <Verifier />
        </Container>
    );
};

export default ContractCertification;
